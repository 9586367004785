export const APIS = {
  // ログイン関連
  LOGIN: "auth/login/",
  LOGOUT: "auth/logout/",

  CONNECTED_ADMIN: "v3/admin",

  // データ取得関連
  COMPANY: "v3/company",
  GET_COMPANY_INFO: "v3/company/info",
  COMPANY_INFO_SETTING: "v3/company/info/setting",
  COMPANY_PAYMENT_SETTING: "v3/company/payment/setting",
  COMPANY_REQUIREMENT: "v3/company/requirement",
  COMPANY_CUSTOM_ITEM: "v3/company/customItem",
  COMPANY_PASSWORD: "v3/company/password",
  COMPANY_LIST: "v3/company/list",
  COMPANY_SELECT: "v3/company/select",
  COMPANY_CHECK: "v3/company/check",

  // メール関連
  MAIL_SETTING: "v3/mail/setting",

  // 特徴
  FEATURE_SETTING: "v3/feature/setting",

  // Ip Address
  IP_ADDRESS: "v3/ipAddress",
  GET_IP_ADDRESS_CURRENT: "v3/ipAddress/current",

  // Accessable App
  UPDATE_IS_ACCESSABLE_APP: "v3/updateIsAccessableApp",

  //Contact us
  CONTACT_US: "v3/contactUs",

  //Admin
  ADMIN_ACCOUNTINFO: "v3/admin/accountInfo",
  ADMIN_EMAIL: "v3/admin/email",
  ADMIN_PASSWORD: "v3/admin/password",
  ADMIN_REPASSWORDMAIL: "v3/admin/rePasswordMail",
  ADMIN_RESET_PASSWORD: "v3/admin/resetPassword",

  //Shop
  GET_SHOP_LIST: "v3/shop/list",
  GET_SHOP_LIST_PAGINATED: "v3/app/shop/list",
  GET_SHOPS: "v3/shops",
  SHOP_QR_URL: "v3/shop/qr/url",
  SHOP_ADMIN_LIST: "v3/shop/admin/{idAdmin}/list",
  SHOP_ADMIN_RELATION: "v3/shop/admin/{idAdmin}",
  SHOP_CSV: "v3/shop/csv",
  SHOP_MANUAL_MAKE: "v3/shop/manual/make",
  SHOP_MANUAL: "v3/shop/manual",
  SHOP: "v3/shop",
  SHOP_DETAIL: "v3/shop/{idShop}",
  SHOP_DETAIL_MANUAL: "v3/shop/{idShop}/manual",
  SHOP_OFFICE_LIST: "v3/shop/office/{idOffice}/list",
  SHOP_STAFFADDINFO: "v3/shop/staffAddInfo",
  SHOP_OFFICE: "v3/shop/office/{idOffice}",
  SHOP_EXISTS: "v3/shop/exists",
  SHOP_NOT_RELATED_EXISTS: "v3/shop/notRelated/exists",

  //Offices
  GET_OFFICES: "v3/offices",
  OFFICE_LIST: "v3/office/list",
  OFFICE: "v3/office",
  OFFICE_DETAIL: "v3/office/{idOffice}",
  OFFICE_CSV: "v3/office/csv",
  OFFICE_EXISTS: "v3/office/exists",
  MAINOFFICE: "v3/mainOffice",
  MAINOFFICE_LIST: "v3/mainOffice/list",
  MAINOFFICE_DETAIL: "v3/mainOffice/{idOffice}",
  OFFICE_RELATED_MAINOFFICE_LIST: "v3/office/mainOffice/{idOffice}/list",
  OFFICE_MAINOFFICE: "v3/office/mainOffice/{idOffice}",
  MAINOFFICE_EXISTS: "v3/mainOffice/exists",

  // Admin list
  GET_ADMIN_LIST: "v3/admin/list",
  CHECK_EXIST_EMAIL: "v3/admin/existEmail",
  VERIFY_EMAIL: "v3/admin/verificationEmail",
  VERIFY_EMAIL_ONLY: "v3/admin/verification",
  ADD_ADMIN: "v3/admin",
  ADMIN_CSV: "v3/admin/csv",
  ADMIN_ACCOUNT: "v3/admin/{idAdmin}",
  ADMIN_SHOP_LIST: "v3/admin/shop/{idShop}/list",
  ADMIN_SHOP_RELATION: "v3/admin/shop/{idShop}",
  ADMIN_AUTHORITY_LIST: "v3/admin/{authority}/list",
  ADMIN_CONTRACTOR: "v3/admin/contractor/{idAdmin}",
  ADMIN_APPROVAL_LOG: "v3/admin/{idAdmin}/getApprovalLog",
  ADMIN_GET_LIST_APPROVER: "v3/admin/{idStaff}/getListApprover",
  
  //Staff
  STAFF_INPUTSTATUS_LIST: "v3/staff/inputStatus/list",
  STAFF_LIST: "v3/staff/list",
  STAFF_CHECK_EXIST_EMAIL: "v3/staff/existEmail",
  STAFF_CSV: "v3/staff/csv",
  STAFF_CSV_FORMAT: "v3/staff/csv/format",
  STAFF_CSV_SPECIFY: "v3/staff/csv/specify",
  STAFF_UPDATETARGET_LIST: "v3/staff/updateTarget/list",
  CSV_FORMAT_DETAIL: "v3/csv/format/{formatKind}/{id}",
  STAFF_DETAIL: "v3/staff/{idStaff}",
  STAFF: "v3/staff",
  STAFF_ALERT_CONFIRM: "v3/staff/alert/confirm",
  STAFF_REQUEST: "v3/staff/{idStaff}/request",
  STAFF_OCR: "v3/staff/{idStaff}/ocr",
  STAFF_PLEDGE: "v3/staff/{idStaff}/pledge",
  STAFF_ETC: "v3/staff/{idStaff}/etc",
  STAFF_VACATION: "v3/staff/{idStaff}/vacation",
  STAFF_LOG: "v3/staff/{idStaff}/log/{logType}",
  STAFF_LOG_RESIDENCE: "v3/staff/{idStaff}/log/residence",
  STAFF_RESIDENCE_CARD_APPROVAL: "v3/staff/{idStaff}/residenceCard/approval",
  STAFF_RESIDENCE_CARD_REJECT: "v3/staff/{idStaff}/residenceCard/reject",
  STAFF_VERIFY: "v3/staff/verify",
  STAFF_LIMIT: "v3/staff/limit",
  STAFF_UPLOAD_RESIDENCE_CARD: "v3/staff/{idStaff}/upload/residenceCard",
  STAFF_UPLOAD_DOCUMENT: "v3/staff/upload/document",
  STAFF_CHECK_RESIDENCE_CARD_NUMBER: "v3/staff/residenceCardNumber",
  STAFF_FROM_RESIDENCE_CARD: "v3/staff/residenceCard/{cardId}",
  STAFF_REMIND_EXPIRING_MAIL: "v3/staff/{idStaff}/remindExpiringMail",
  STAFF_POST_CODE: "v3/staff/postCode",
  STAFF_SEND_APPROVAL: "v3/staff/{idStaff}/sendApproval",
  STAFF_CANCEL_APPROVAL: "v3/staff/{idStaff}/cancelApproval",
  STAFF_APPROVAL_LOG: "v3/staff/{idStaff}/getApprovalLog",
  STAFF_APPROVE_APPROVAL: "v3/staff/{idStaff}/approveApproval",
  STAFF_REJECT_APPROVAL: "v3/staff/{idStaff}/rejectApproval",
  STAFF_CHECK_APPROVAL_STATUS: "v3/staff/{idStaff}/checkApprovalStatus",
  STAFF_INFORMATION_APPROVAL: "v3/staff/{idStaff}/getInformationApproval",
  STAFF_VERIFY_RESIDENCE_CARD: "v3/staff/verifyResidenceCard/{idStaff}",
  STAFF_RENEWED_RESIDENCE_CARD_URL: "v3/staff/renewedResidenceCardURL/{idStaff}",
  STAFF_UPLOAD_WORK_DESIGNATION: "v3/staff/uploadWorkDesignation",
  STAFF_SEND_REJOINING_ALERT: "v3/staff/{idStaff}/sendRejoiningAlert",
  STAFF_UPDATE_IS_RESIGNED_STAFF_RESIDENCE_CARD: "v3/staff/updateIsResignedStaffResidenceCard",
  STAFF_EXPIRY_LIST: "v3/staff/expiry/list",
  STAFF_EXPIRY_DETAIL: "v3/staff/{idStaff}/expiry/detail",

  // Payment
  PAYMENT_LIST: "v3/payment/list",
  PAYMENT_DETAIL: "v3/payment/detail",
  PAYMENT_SHOP: "v3/payment/shop",

  PAYMENT_ACTIVE_STAFF_LIST: "v3/payment/activeStaff/list",
  PAYMENT_NEW_STAFF_LIST: "v3/payment/newStaff/list",

  // Notice
  NOTICE_LIST: "v3/notice/list",
  NOTICE_NEWCOUNT: "v3/notice/newCount",
  NOTICE_READ: "v3/notice/read",

  //Picture
  PICTURE_UPLOAD: "v3/picture/upload",
  PICTURE_UPLOAD_ANY: "v3/picture/uploadAny",

  // Card
  ZAIMANE_CARD: "v3/zaimane/{idCard}",
  ZAIMANE_CARD_REPORT: "v3/zaimane/{idCard}/report",

  //helloWorks
  HELLOWORKS: "v3/helloWorks",
  HELLOWORK_ELECTRONIC_LIST: "v3/hellowork/electronic/list",
  HELLOWORK_ELECTRONIC_CSV: "v3/hellowork/electronic/csv",
  HELLOWORK_ELECTRONIC_OFFICE_WORKING_LIST:
    "v3/hellowork/electronic/{idOffice}/working/list",
  HELLOWORK_ELECTRONIC_CSV_OFFICE:
    "v3/hellowork/electronic/{idOffice}/working/csv",
  HELLOWORK_ELECTRONIC_OFFICE_RESIGNED_LIST:
    "v3/hellowork/electronic/{idOffice}/resigned/list",
  HELLOWORK_ELECTRONIC_OFFICE_RESIGNED:
    "v3/hellowork/electronic/{idOffice}/resigned",
  HELLOWORK_PAPER_LIST: "v3/hellowork/paper/list",
  HELLOWORK_PAPER_XLSX: "v3/hellowork/paper/xlsx",
  HELLOWORK_PAPER_HELLOWORKOFFICE_LIST:
    "v3/hellowork/paper/{idHelloworkOffice}/list",

  //Zaimane
  ZAIMANE_LIST: "v3/zaimane/list",
  ZAIMANE_JUDGE: "v3/zaimane/judge",
  ZAIMANE_CARD_CHECK: "v3/cards/{cardId}",

  //zipcloud.ibsnet.co.jp
  ZIPCLOUD_API_SEARCH: "https://zipcloud.ibsnet.co.jp/api/search",

  //Terms
  TERMS: "v3/terms",

  //PrivacyPolicy
  PRIVACYPHOLICY: "v3/privacyPolicy",

  //Dashboard
  DASHBOARD_HELLOWORK: "v3/dashboard/helloWork",
  DASHBOARD_ALERT: "v3/dashboard/alert",

  //Plan select
  PLAN_SELECT: "v3/plan/select",

  COMPANY_POPUP_FLAG: "v3/company/popup/flag",
};
